<template>
  <div style="max-height: 480px; position: relative; z-index: 150">
  <simplebar data-simplebar-auto-hide="false" style="max-height: 480px">
      <UpdateItem v-for="update in updates" :key="update.id" :update="update"></UpdateItem>
  </simplebar>
  </div>
</template>

<script>
import UpdateItem from './UpdateItem'
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import { mapGetters } from "vuex";

export default {
  components: {
    UpdateItem,
    simplebar
  },
  computed: mapGetters({
    updates: 'getUpdates'
  })
}
</script>
