<template>
  <div :style="{'backgroundColor':category.color, color: '#fff'}" :class="'category-item category-item__'+category.slug">
    {{category.name}}
  </div>
</template>

<script>
export default {
  props: ['category']
}
</script>

<style scoped>
  .category-item {
    text-align: center;
    background-color: grey;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
    list-style-type: none;
    margin: 0px;
    overflow: hidden;
    padding: 0px 8px;
    text-transform: uppercase;
    user-select: none;
    margin-right: 4px;
    margin-top: 4px;
  }
</style>