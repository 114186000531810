<template>
  <div id="updates-widget">
    <div role="button" class="icon-wrapper">
      <div id="icon" style="margin-left: 0px; margin-right: 3px; margin-top: 0px;" @click="showUpdates('home')">  <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style="color: rgb(255, 255, 255); height: 32px; width: 32px;"><path fill="currentColor" d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"></path></svg> <div id="badge-wrapper" class="shake" v-if="getCountNewUpdates > 0"><div id="badge" style="bottom: unset; left: unset; right: -2.5px; top: 0px;"><!---->{{getCountNewUpdates}}<!----></div></div> </div>
    </div>
    <div >
      <router-view class="updates-expand"></router-view>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['getVisibleUpdates', 'getCountNewUpdates',"getUpdates"])
  },
  methods: {
  ...mapActions(['allUpdates','refreshLocalStorage']),
    showUpdates(tab) {
      let viewedUpdates = JSON.parse(localStorage.getItem('viewedUpdates'));
      this.getUpdates.forEach((elem) => {
        let isViewed = viewedUpdates.find((el)=> {
          return el.id === elem.id;
        })
        if(!isViewed) {
          viewedUpdates.push({id:elem.id, read: false});
        }
      })
      localStorage.setItem('viewedUpdates', JSON.stringify(viewedUpdates))
      this.refreshLocalStorage(viewedUpdates)
      if (this.$route.path === '/') {
        this.$router.push({name: tab})
      } else {
        this.$router.push({path: '/'})
      }
    }
  },
  created() {
    this.allUpdates()
  },
  watch: {
    '$route.name'() {
        const router = this.$router;
        if (this.$route.name) {
          if (!document.getElementById('widget-backdrop')) {
            let backdrop = document.createElement('div');
            backdrop.setAttribute('id','widget-backdrop');
            backdrop.addEventListener('click', function() {
              router.push({path: '/'})
            })
            document.body.prepend(backdrop)
          }

        } else {
            document.body.removeChild(document.getElementById('widget-backdrop'))
        }
    }
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
.route-link {
  text-decoration: none;
}
#updates-widget-header {
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
  position: relative;
  user-select: none;
  text-align: center;
  z-index: 151;
  background: linear-gradient(
      125deg
      , rgb(34, 84, 235) -10%, rgb(16, 54, 168) 100%);
  color: rgb(255, 255, 255);
}

#updates-widget-header a:not(#left-arrow) {
  display: inline-block;
  padding: 17px 16px;
  text-decoration: none;
}

#updates-widget-header a {
  color: #fff !important;
}
#header-title {
  align-items: center;
  display: flex;
  justify-content: center;
}
#updates-widget-header svg.external-link {
  height: 14px;
  margin-bottom: 1px;
  margin-left: 10px;
  opacity: 0.55;
  transition: all 0.4s ease 0s;
  width: 14px;
}

#updates-widget-header #left-arrow svg {
  color: rgba(255,255,255,0.8);
  fill: rgba(255,255,255,0.8);
  height: 24px !important;
  width: 24px !important;
}
.icon-wrapper {
  display: flex;
  justify-content: center;
}
#updates-widget-header #left-arrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 36px;
}

#updates-widget-header #left-arrow:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}
#updates-widget {
  position: relative;
  z-index: 15;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  max-width: 480px;
  width: 100%;
  border-radius: 8px;
}
#icon {
  cursor: pointer;
  height: fit-content;
  position: relative;
  width: fit-content;
}
#badge-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
  display: block;
  height: 21px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transition: all 0.4s ease 0s;
  width: 21px;
  background: #ef3b39;
  --pulse-distance: 34.125px;
  margin: 0px;
}
.shake {
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shake;
  animation-timing-function: cubic-bezier(0.15, 0.03, 0.06, 0.39);
}
#badge {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  height: 100%;
  justify-content: center;
  text-rendering: optimizelegibility;
  user-select: none;
  color: #fff;
  width: 100%;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}

#widget-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0,0,0,0.5);
  height: 100vh;
  width: 100vw;

}

.updates-expand {
  width: 480px;
  /* max-width: 100%; */
  /* max-width: 480px; */
  border-radius: 10px;
  overflow: hidden;
  left: -240px;
  right: 0;
  position: absolute;
  z-index: 101;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 490px) {
  .updates-expand {
    width: 320px;
  }
}

/*#widget-backdrop {*/
/*  inset: 0px;*/
/*  position: fixed;*/
/*  z-index: 14;*/
/*  background: rgba(0, 0, 0, 0.6);*/
/*}*/

</style>
