import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vueCustomElement from 'vue-custom-element'

Vue.config.productionTip = false
Vue.use(vueCustomElement)
App.store = store
App.router = router
Vue.customElement('updates-widget', App)

// new Vue({
//   store,
//   router,
//   render: h => h(App),
// }).$mount('#app')
