import axios from "axios";

export default {
    state: {
        updates: [],
        viewedUpdates: 0,
        visibleUpdates: false,
    },
    getters: {
        getUpdates(state) {
            return state.updates
        },
        getCountNewUpdates(state) {
            return state.viewedUpdates

        },
        getSingleUpdate(state) {
            return (id) => state.updates.find((element) => {
                return element.id === id;
            })
        },
        getVisibleUpdates(state) {
            return state.visibleUpdates
        }
    },
    mutations: {
        refreshUpdates(state, updates) {
            state.updates = updates.map(item => {
                return {
                    id: item.id,
                    date: item.date,
                    link: item.link,
                    title: item.title,
                    excerpt: item.excerpt,
                    updates_category: item.updates_category,
                    image: item.images.large,
                    content: item.content.rendered.replaceAll('<a', '<a target="_blank"')
                }
            })
        },
        refreshLocalStorage(state, value) {
            state.viewedUpdates = value
        }
    },
    actions: {
        refreshLocalStorage({ commit, getters }, value) {
            if (localStorage.getItem('viewedUpdates')) {
                // state.viewedUpdates = JSON.parse(localStorage.getItem('viewedUpdates'));
            } else {
                localStorage.setItem('viewedUpdates', JSON.stringify(value));
            }
            const viewedUpdates = getters.getUpdates.filter(x => {
                let elementsOfArray2PresentInArray1 = JSON.parse(localStorage.getItem('viewedUpdates')).filter(y => {
                    return x.id === y.id
                });
                if (elementsOfArray2PresentInArray1.length > 0) {
                    return false
                } else {
                    return true;
                }
            }).length;
            commit('refreshLocalStorage', viewedUpdates)
        },
        allUpdates({commit, dispatch}) {
            axios
                .get('https://dataforseo.com/wp-json/wp/v2/update').then(response => {
                    commit('refreshUpdates', response.data)
                    dispatch('refreshLocalStorage', [{id: 20934, read: false}])
                })
                .catch(err => console.error(err))
        }
    }
}
