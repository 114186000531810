<template>
  <div @click="changeViewedIds(update.id)" :class="isRead(update.id)">
    <router-link :to="'/'+update.id" class="update-item">
      <span class="update-date">{{getDateAgo(update.date)}}</span>
    <h4 v-html="update.title.rendered" class="update-title"></h4>
    <div v-html="update.excerpt.rendered" class="update-summary"></div>
    <UpdateCategory v-for="category in update.updates_category" :key="category.id" :category="category"></UpdateCategory>
    </router-link>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
  import UpdateCategory from './UpdateCategory'

  export default {
    props: ['update'],
    components: {
      UpdateCategory
    },
    computed: mapGetters({
      updateSingle: 'getSingleUpdate',
    }),
    methods: {
      ...mapActions(['refreshLocalStorage']),
      changeViewedIds(id) {
        let viewedUpdates = JSON.parse(localStorage.getItem('viewedUpdates'))
        let isViewed = viewedUpdates.findIndex((el)=> {
          return el.id === id;
        })
        viewedUpdates[isViewed].read = true;
        localStorage.setItem('viewedUpdates', JSON.stringify(viewedUpdates))
        this.refreshLocalStorage(viewedUpdates)
        // if (!isViewed) {
        //   viewedUpdates.push({id, read: true})
        //   localStorage.setItem('viewedUpdates', JSON.stringify(viewedUpdates))
        //   this.refreshLocalStorage(viewedUpdates)
        // }
      },
      getDateAgo(date) {
        return this.timeSince(new Date(date))

      },
      timeSince(date) {
        const seconds = Math.floor((new Date() - date) / 1000);

        let interval = seconds / 31536000;

        if (interval > 1) {
          return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
          return Math.floor(interval) + " months ago";
        }
        interval = seconds / 86400;
        if (interval > 2) {
          return Math.floor(interval) + " days ago";
        } else if(interval > 1) {
          return "Yesterday"
        } else if (interval < 1) {
          return "Today";
        }
      },
      isRead(id) {
        let viewedUpdates = JSON.parse(localStorage.getItem('viewedUpdates'));
        let readUpdate = viewedUpdates.find((el)=> {
          return el.id === id && el.read;
        })
        return readUpdate ? 'item-read': 'item-unread';
      }
    }

  }
</script>

<style scoped>
  .update-item {
    display: block;
    text-decoration: none;
    max-width: 100%;
    text-align: left;
    background: #fff;
    padding: 18px 16px;
    user-select: none;
    border-bottom: 1px solid #E9E9E9;
  }
  .item-read {
    border-left: 3px solid #d9d9d9;
  }
  .item-unread {
    border-left: 3px solid #2254eb;
  }
  .update-date {
    color: rgb(102, 102, 102);
    margin-bottom: 0.83rem;
    display: block;
  }
  .update-title {
    color: rgb(0, 0, 0);
    font-size: 15px;
    margin-top: 0px;
  }
  .update-summary {
    line-height: 1.4em;
    color: rgb(102, 102, 102);
    font-size: 14px;
  }
</style>
