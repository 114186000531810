<template>
  <div>
<!--    <div id="open-updates-button">whats new {{viewedUpdates}}</div> v-click-outside = "toggleBlock"-->
    <div >
      <div id="updates-widget-header">
        <a target="_blank" href="https://dataforseo.com/updates"><div id="header-title">
          <!---->Latest Updates <svg aria-hidden="true" class="external-link" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM474.67,0H316a28,28,0,0,0-28,28V46.71A28,28,0,0,0,316.79,73.9L384,72,135.06,319.09l-.06.06a24,24,0,0,0,0,33.94l23.94,23.85.06.06a24,24,0,0,0,33.91-.09L440,128l-1.88,67.22V196a28,28,0,0,0,28,28H484a28,28,0,0,0,28-28V37.33h0A37.33,37.33,0,0,0,474.67,0Z"></path></svg><!----></div></a>
      </div>
      <div ><UpdatesWrapper></UpdatesWrapper></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import UpdatesWrapper from "./UpdatesWrapper";

export default {
  name: 'App',
  data() {
    return {
      visibleBlock: true
    }
  },
  components: {
    UpdatesWrapper,
  },
  computed: mapGetters({
    viewedUpdates: 'getCountNewUpdates'
  }),
  methods: {
    toggleBlock(event) {
      if (event.target.id === 'open-updates-button') {
        if (!this.visibleBlock) {
          this.visibleBlock = true
        } else {
          this.visibleBlock = false
        }
      } else {
        this.visibleBlock = false
      }

    }
  },
  directives: {
    'click-outside': {
      bind: function(el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
          const compName = vNode.context.name
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
          if (compName) { warn += `Found in component '${compName}'` }

          console.warn(warn)
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler

        // add Event Listeners
        document.addEventListener('click', handler)
      },

      unbind: function(el) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null

      }
    }
  }
}
</script>
