<template>
    <div>
      <div id="updates-widget-header">
        <router-link to="/home" id="left-arrow" aria-label="Back"><!---->
          <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" style="pointer-events: none; display: block; width: 100%; height: 100%;">
            <g><path d="M0 0h24v24H0z" fill="none"></path>
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
            </g></svg><!---->
        </router-link>
        <a target="_blank" href="https://dataforseo.com/updates"><div id="header-title"><!---->Latest Updates <!----></div></a>
      </div>
      <simplebar data-simplebar-auto-hide="false" style="height: 480px; max-height: 100%; position: relative; z-index: 150">
        <div class="single-update">
          <h1 v-html="update.title.rendered" v-if="!loading"></h1>
          <img :src="update.image" :alt="update.title.rendered" class="update-image" v-if="update.image">
          <div v-html="update.content" class="single-update-content"></div>
        </div>
      </simplebar>
    </div>


</template>

<script>
  import {mapGetters} from "vuex";
  import simplebar from "simplebar-vue";
  import 'simplebar/dist/simplebar.min.css';

  export default {
    components: {
      simplebar
    },
    data() {
      return {
        update: {},
        loading: true
      }
    },
    computed: mapGetters(['getSingleUpdate']),
    mounted() {
      this.update = this.getSingleUpdate(+this.$route.params.id)
      this.loading = false
    }
  }
</script>

<style>
.update-image {
  height: auto;
  width: 100%;
  display: block;
  margin: 10px 0;
}
.single-update {
  max-width: 100%;
  text-align: left;
  padding: 18px 16px;
  user-select: none;
  background: #fff;
}
.single-update h1 {
  color: #5b657c !important;
}

.single-update pre {
  color: #2c3e50;
  background: #fff !important;
  border: none !important;
}
.single-update-content {
  width: 100%;
}
.single-update-content img {
  max-width: 100%;
  height: auto;
  display: block;
}
.single-update-content pre {
  white-space: pre-wrap;
}

</style>
