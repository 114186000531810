import Vue from 'vue'
import VueRouter from 'vue-router'
import SingleUpdate from './components/SingleUpdate'
import Home from './components/Home'

Vue.use(VueRouter)

export default new VueRouter({
	routes: [{
		path: '/home',
		name: 'home',
		component: Home
	}, {
		path: '/:id',
		name: 'single',
		component: SingleUpdate
	}],
	mode: "abstract"
})
